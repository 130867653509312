<template>
	<!-- 登录 注册 忘记密码 页面 form表单父容器 -->
	<div class="user-form-container">
		<!-- <div class="protective-night" /> -->
		<swiper class="handle-media-sceen" />
		<div class="user-header-title-wrap">
			<div class="user-container-header">
				<div class="user-header-title">
					{{ message }}
				</div>
				<div class="divider" />
				<div class="title">
					<img src="~@/assets/images/login/title.png" alt="" v-if="!sky" />
					<h1 style="font-size: 36px; font-weight: 900" v-else>{{ title }}</h1>
				</div>
			</div>
		</div>

		<div class="user-form" :style="{ 'padding-top': `${customPadding}px` }">
			<div v-if="hasCutIcon" class="dog-eared-tip">
				<img :class="{ 'lock-icon': loginType === 1 }" :src="loginTypeIcon" alt="" @click="handleCutLoginType(loginType)" />
			</div>
			<div class="title-line" :style="{ 'margin-bottom': `${titleLineMargin}px` }">
				<img :src="logo" alt="" v-if="!sky" />
				<span>{{ appTitle(!sky ? $store.state.user.serverName : title) }}</span>
			</div>
			<slot />
		</div>
	</div>
</template>

<script>
import LOCK from '@/assets/images/login/lock.png';
import PHONE from '@/assets/images/login/phone.png';
import Swiper from './components/Swiper.vue';
import { mapGetters } from 'vuex';

const url_group = ['https://101.132.121.145'];

export default {
	name: 'FormContainer',
	components: {
		Swiper
	},
	props: {
		message: {
			type: String,
			default: '欢迎登录'
		},
		logo: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: '无人机集群操作系统'
		},
		hasCutIcon: {
			type: Boolean,
			default: true
		},
		customPadding: {
			type: Number,
			default: 47
		},
		titleLineMargin: {
			type: Number,
			default: 33
		},
		hasLogo: {
			type: Boolean,
			default: true
		},
		hasIMGTitle: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			loginType: 0 // 0 : 账号密码登录 ；1 手机号验证码登录
		};
	},
	computed: {
		...mapGetters(['appTitle']),

		sky() {
			return url_group.findIndex(item => window.location.href.split('#')[0].indexOf(item) > -1) > -1;
		},

		loginTypeIcon() {
			return this.loginType === 1 ? LOCK : PHONE;
		}
	},
	methods: {
		handleCutLoginType(loginType) {
			this.loginType = loginType === 1 ? 0 : 1;
			this.$emit('oView', this.loginType);
		}
	}
};
</script>

<style lang="scss" scoped>
.user-form-container {
	width: $-main-user-form-container-width;
	height: $-main-user-form-container-height;
	padding-right: 54px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-image: $-main-user-form-container-background-image;

	.protective-night {
		height: 100%;
		width: 100%;
		position: absolute;
		background-color: #31313199;
	}

	.user-header-title-wrap {
		.user-container-header {
			max-width: 500px;
			padding: 42px;
			position: absolute;
			top: 0;

			.user-header-title {
				font-size: 24px;
				font-family: Source Han Sans CN;
				font-weight: 300;
				color: #ffffff;
			}

			.divider {
				width: 32px;
				height: 2px;
				margin: 22px 0;
				background: #ffffff;
			}

			.title {
				font-size: 36px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #ffffff;
			}
		}
	}

	.user-form {
		min-width: $-main-desktop-user-form-width;
		min-height: $-main-desktop-user-form-height;
		padding: $-main-desktop-user-form-padding;
		position: relative;
		// top: $-main-desktop-user-form-top;
		// right: $-main-desktop-user-form-right;
		//background-color: $-main-user-form-background-color;
		background-color: var(--el-fill-color-light);
		box-sizing: border-box;

		.dog-eared-tip {
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			border-top: 40px solid $-main-user-form-dog-eared-tip-color;
			border-left: 40px solid transparent;

			img {
				width: 9px;
				height: 15px;
				position: absolute;
				top: -35px;
				right: 6px;
				color: #fff;
				cursor: pointer;
			}

			.lock-icon {
				width: 12px;
				height: 14px;
			}
		}

		.title-line {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-bottom: 33px;

			img {
				width: 42px;
				height: 25px;
				margin-right: 8px;
			}

			span {
				font-size: 16px;
				color: #050505;
				font-weight: 500;
			}
		}

		/deep/ .el-form {
			.el-form-item {
				margin-bottom: 21px;

				.el-form-item__label {
					line-height: 1;
					margin-bottom: 11px;
					color: #050505;
				}

				.el-form-item__content {
					.el-input {
						// font-size: var(--size);
						.el-input__inner {
							background: #f6f6f6;
							border-color: #d9d9d9;
							color: #050505;
						}

						&.el-input-group {
							.el-input-group__append {
								background: #f6f6f6;
								border-color: #d9d9d9;
								color: #050505;
							}
						}
					}

					.form-item-container {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;

						&.el-hidden {
							visibility: hidden;
						}

						.wechat-code {
							img {
								width: 18px;
								height: 14px;
							}
						}
					}

					.el-input {
						&.el-input-group--append {
							.el-input-group__append,
							.el-input-group__prepend {
								color: #050505;
							}
						}

						.eye {
							width: 14px;
							margin-right: 6px;
							cursor: pointer;
						}
					}

					.el-button {
						width: 100%;
						height: 40px;
						// box-shadow: 0px 0px 92px 0px rgba(125, 125, 125, 0.28);
					}
				}
			}
		}
	}
}
</style>

<style lang="scss" scoped>
@media all and (min-width: 340px) and (max-width: 630px) {
	.user-form-container {
		width: $-main-desktop-login-form-container-width;
	}
	.handle-media-sceen,
	.user-header-title-wrap {
		display: none;
	}
}
@media all and (min-width: 630px) and (max-width: 1380px) {
	.user-form-container {
		width: $-main-desktop-login-form-container-width;
	}
	.handle-media-sceen,
	.user-header-title-wrap {
		display: none;
	}
}
</style>
