<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" />
      <div class="swiper-slide" />
      <div class="swiper-slide" />
    </div>
  </div>
</template>

<script>
// 引用swiper
import Swiper from 'swiper';
import 'swiper/css/swiper.css';

export default {
  mounted() {
    this.getSwiper();
  },
  methods: {
    getSwiper() {
      return new Swiper('.swiper-container', {
        // direction: 'vertical', 垂直切换选项
        loop: true, // 开启循环模式选项
        autoplay: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;

  .swiper-slide {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .swiper-slide:nth-child(2) {
    background-image: url('~@/assets/images/login/swiper/1_03.jpg');
  }

  .swiper-slide:nth-child(3) {
    background-image: url('~@/assets/images/login/swiper/2_03.jpg');
  }

  .swiper-slide:nth-child(4) {
    background-image: url('~@/assets/images/login/swiper/3_03.jpg');
  }

  .swiper-slide:nth-child(5) {
    background-image: url('~@/assets/images/login/swiper/4_03.jpg');
  }
}
</style>
